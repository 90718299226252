:root{
    --primary-navy :  #002C54;
    --primary-red : #C5001A ;
    --primary-pink: #FDF6F6;
}

 .footer {
    background-color: var(--primary-navy);
    color: white;
    /* padding: 1rem 1rem; */
    text-align: center;
}

p{
    padding-top: 25px;
    padding-left: 10px;
}

.nav-footer{
    display: flex;
    justify-content: space-between;
}

.menu {
    display: flex;
    list-style-type: none;
    font-weight: 600;
}

.btn-menu {
    padding: 1rem;
    cursor: pointer;
    margin: 10px;
    border-bottom: 2px solid transparent;
}

.btn-menu:hover {
    border-bottom: 2px solid var(--primary-red);
}

.logo {
    margin-left: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20%;
}



