.container {
    max-width: 95%;
    margin: auto;
    padding: 20px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    margin-bottom: 2rem;
}
h1, h2 {
    color: #5a5a5a;
}
h1 {
    text-align: center;
}
.faq-item {
    margin-bottom: 20px;
}
.faq-item h2 {
    font-size: 18px;
    margin-bottom: 10px;
}
.faq-item p {
    margin: 0;
}