.container {
    max-width: 95%;
    margin: auto;
    padding: 20px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}
h1, h2 {
    color: #F41930;
}
h1 {
    text-align: center;
}
p {
    margin-bottom: 15px;
}
.mission, .vision, .values {
    margin-bottom: 30px;
}