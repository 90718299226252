.top-Banner {
    width: 100%;
    height: 500px;
    background-image: url(../../assets/donate_banner.png);
    background-size: cover;
}

.enquiry-form {
    display: flex;
    flex-direction: column-reverse;
    align-items: flex-end;
}

.form {
    background: white;
    width: 15rem;
    display: flex;
    flex-direction: column;
    padding: 1rem;
    justify-content: center;
    margin-right: 15rem;
    margin-top: 10rem;
    border-radius: 5px;
}

.form-element {
    width: 5rem;
    border: none;
}

select {
    padding: 10px 20px;
    margin-bottom: 20px;
    width: 15rem;
}

.btn-submit {
    padding: 10px 20px;
    width: 15rem;
    background-color: #C5001A;
    border: none;
    color: white;
    font-weight: 600;
    cursor: pointer;
}

@media (max-width: 768px) {
    .top-Banner {
        height: auto;
        padding: 20px 0;
    }

    .form {
        margin-top: 0;
        margin-right: 0;
        padding: 1.5rem;
    }
}


.latest-request {
    height: 500px;
    padding: 1rem;
    display: flex;
}

.requests {
    width: 50%;
    border-radius: 10px;
    background-color: white;
}

.requests>h4 {
    text-align: center;
}

.donoruse {
    width: 50%;
    text-align: center;
}

.donoruse>h3 {
    color: #C5001A;
    text-decoration: underline;
    margin-left: 10rem;
    margin-right: 10rem;
    margin-bottom: -1rem;
}

.donoruse>p {
    text-align: justify;
    padding-left: 3rem;
    padding-right: 3rem;
}

/* Media query for smaller screens */
@media (max-width: 768px) {
    .requests, .donoruse {
        width: 90%; /* Full width with some padding */
        margin: 0 auto 1.5rem auto; /* Center and add space below each section */
    }

    .latest-request {
        height: auto;
        padding: 1rem;
        display: flex;
        flex-direction: column;
    }
    
    .donoruse>h3 {
        margin-left: auto;
        margin-right: auto;
        text-align: center;
    }

    .donoruse>p {
        padding-left: 1rem;
        padding-right: 1rem;
    }
}

.form-requests {
    height: 600px;
    padding: 1rem;
    display: flex;
}

.donorform, .requestform {
    width: 50%;
    text-align: center;
    background: white;
    border-radius: 10px;
    margin: 1rem;
}


.donorform > h4 {
    text-decoration: none;
}

.form-request {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.form-request .form-elements {
    width: 75%;
}

.form-request .select-item {
    margin: 10px;
    width: 100%;
}

.form-request .input-item {
    padding: 10px 20px;
    margin: 10px;
    width: 92%;
}

.donation-eligibility{
    background-color: white;
    width: 50%;
}

.donation-eligibility h4 {
    text-align: center;
}

.donation-eligibility > ul {
    padding-left: 20px;
    line-height: 10px;
}

.donation-eligibility li {
    list-style: none;
    line-height: 2rem;
}
@media (max-width: 768px) {
    .form-requests {
        flex-direction: column;
        align-items: center;
        height: auto;
    }

    .donorform, .requestform, .donation-eligibility {
        width: 90%; /* Full width with some padding */
        margin: 1rem 0; /* Adjust margins for vertical stacking */
        height: auto;
    }
}