:root{
    --primary-navy :  #002C54;
    --primary-red : #C5001A ;
    --primary-pink: #FDF6F6;
}

.header {
    background-color: var(--primary-navy);
    color: white;
    /* padding: 1rem 1rem; */
    text-align: center;
    position: sticky;
}

.nav{
    display: flex;
    justify-content: space-between;
}

.menu {
    display: flex;
    list-style-type: none;
    font-weight: 600;
    align-items: center;
}

.btn-menu {
    padding: 1rem;
    cursor: pointer;
    margin: 10px;
    text-decoration: none;
    border-bottom: 2px solid transparent;
    color: white;
}

.btn-menu:hover {
    border-bottom: 2px solid var(--primary-red);
}

.logo {
    margin-left: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20%;
}



/* Menu icon styling */
.menu-icon {
    display: none;
    font-size: 2rem;
    cursor: pointer;
    color: white;
    margin: 1rem;
}

/* Responsive styles */
@media (max-width: 768px) {
    .menu {
        position: absolute;
        top: 60px;
        right: 0;
        background-color: var(--primary-navy);
        flex-direction: column;
        width: 100%;
        max-height: 0;
        overflow: hidden;
        visibility: hidden;
        opacity: 0;
        z-index: 1001;
    }

    .menu.active {
        max-height: 300px; /* Adjust height as needed */
        visibility: visible;
        opacity: 1;
    }

    .menu li {
        text-align: center;
        margin: 10px 0;
    }

    .menu-icon {
        display: block;
    }

    .logo {
        width: auto;
    }
}
