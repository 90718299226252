.contact {
    font-family: Arial, sans-serif;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    background-color: #f4f4f4;
    margin-bottom: 2rem;
}
.contact-form {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    width: 400px;
}
.form-group {
    margin-bottom: 15px;
}
.form-group label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
}
.form-group input, .form-group textarea {
    width: calc(100% - 20px);
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
}
.form-group textarea {
    resize: vertical;
    height: 100px;
}
.form-group.row {
    display: flex;
    justify-content: space-between;
}
.form-group.row .col {
    width: calc(50% - 10px);
}
.submit-btn {
    width: 100%;
    padding: 10px;
    background-color: #8b0000;
    color: #fff;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
}
.submit-btn:hover {
    background-color: #a52a2a;
}