h1, h2 {
    color: #F51629;
}
.container {
    padding-left: 5rem;
    padding-right: 5rem;
    padding-bottom: 2rem;
    margin: auto;
}
.contact-info {
    background-color: #D3E8FD;
    padding: 10px;
    border-left: 5px solid #F51629;
}
.register-info {
    background-color: #D3E8FD;
    padding: 10px;
    border-left: 5px solid #F51629;
}